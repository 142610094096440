import Vue from 'vue'
import Tawk from 'vue-tawk'

Vue.use(Tawk, {
  tawkSrc: 'https://embed.tawk.to/63c9feb9c2f1ac1e202ea427/1gn6gm1s3',
})

;(window as any).Tawk_API.customStyle = {
  zIndex: 4,
}
